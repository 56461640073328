app.components.layout = {
    global: true,

    /**
     * Attaches FastClick, removes no-js class, and detects IE11.
     */
    addListener: function () {

        // Remove 'no-js' class indicating JavaScript availability.
        $("html").removeClass("no-js");

        // Add target="_blank" to PDF links.
        $("a[href$='pdf']").attr("target", "_blank");

        // Open External Links In New Window.
        $('a').each(function () {
            if ($(this).attr("href") && ($(this).attr("href").indexOf("#") === 0 || $(this).attr("href").indexOf("javascript") === 0 || typeof this.href !== 'string')) {
                return;
            }

            var a = new RegExp('/' + window.location.host + '/');
            var b = new RegExp(/(mailto:.*)/g);

            if (!a.test(this.href) && !b.test(this.href)) {
                $(this).attr('rel', 'external');
                $(this).attr("target", "_blank");
            } else {
                $(this).attr('rel', 'internal');
            }
        });

        // Add 'hasImage' class to links that contain images.
        $('a:has(img)').addClass('hasImage');

        // Scroll to element functionality for hash links.
        let scrollToElement = (hash) => {
            hash = hash.replace('#', '');
            let elementOffset = $('.section--' + hash).offset();
            if (!elementOffset) {
                elementOffset = $('#' + hash).offset();
            }
            if (elementOffset) {
                elementOffset = elementOffset.top;
                $('html, body').stop().animate({
                    'scrollTop': elementOffset - $('header').height()
                }, 750, 'swing', function () {
                    $(document).trigger("scroll");
                });
            }
        }

        // Event listener for hash changes.
        $(window).on('hashchange', function () {
            var hash = window.location.hash;
            $('html.mobile-nav-is-visible').removeClass('mobile-nav-is-visible');
            if (hash) {
                setTimeout(() => {
                    scrollToElement(hash);
                }, 100);
            }
        }).trigger('hashchange');

        // Event listener for internal hash links.
        $(document).on('click', 'a[href^="#"]', function (event) {
            var hash = $.attr(this, 'href');
            if (hash !== '#') {
                event.preventDefault();
                scrollToElement(hash);
                history.pushState(null, null, hash);
            }
        });

    }
};
