app.components.image = {
    onReady: function () {
        if ($('.c-image').length) {
            return true;
        }
    },
    addListener: function () {
        $('.c-image--fill').each(function () {
            $(this).parent('.col').addClass('order-until-md-0');
        });
    }
};
