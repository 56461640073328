import 'aos/dist/aos.js';
import 'aos/dist/aos.css';

app.components.animations = {
    onReady: () => {
        if ($('[data-aos]').length) { // disable on fullscreen
            return true;
        }
    },
    addListener: () => {
        AOS.init({
            disable: false,
            startEvent: 'DOMContentLoaded',
            animatedClassName: 'aos-animate',
            useClassNames: false,
            disableMutationObserver: false,
            debounceDelay: 50,
            throttleDelay: 99,
            offset: 120,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: true,
            mirror: false,
            anchorPlacement: 'top-bottom'
        });
    }
};
