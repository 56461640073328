app.components.ultimatemember = {
    global: false,
    onReady: function () {
        if ($('.um-form').length) {
            return true;
        }
    },
    addListener: function () {
        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js')
        ]).then(() => {
            jcf.replaceAll($('.um-form'));
        });

        $('.um-account-main').find('.um-account-nav a').on('click', function () {
            var el = this;
            $(el).toggleClass('um-account-nav--active');
            $('.um-account-nav').not($(el)).removeClass('um-account-nav--active');
            $(el).parent().next().slideToggle('fast');
            $(".um-account-tab").not($(el).parent().next()).slideUp('fast');
            return false
        });

        $(document).on('click', '.um-member-card-container', function () {
            $(this).toggleClass('um-member-card-container--active');
            $('.um-member-card-container').not($(this)).removeClass('um-member-card-container--active');
            $('.um-member-meta-main', this).slideToggle('fast');
            $(".um-member-meta-main").not( $('.um-member-meta-main', this)).slideUp('fast');
        });

    }
};
