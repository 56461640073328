app.components.form_select = {
    global: false,
    onReady: function () {
        if ($('.c-form_select').length) {
            return true;
        }
    },
    addListener: function () {
        const $container = $('.c-form_select__form-display');
        const $select = $('.c-form_select select');
        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.file.js')
        ]).then(() => {
            jcf.replaceAll($container);
        });
        $select.on('change', function () {
            let formId = $(".c-form_select select option:selected").val();
            var endpoint = '?component=' + "form" + '&options=' + ['name:' + formId, 'template:index'].join(';');
            $.get(endpoint, function (data) {
                $(".c-form_select__form-display").html(data);
                try {
                    // reinitialize wpforms
                    wpforms.ready();
                    // reinitialize file upload control
                    wpformsModernFileUpload.init()
                    // reload checkboxes
                    jcf.replaceAll($container);
                    //TODO: reinitialize conditional logic for formId
                    // don't know how to do this, workaround: load the relevant forms
                    // and hide them vis css in the background on initial pageload (see index.twig)

                    $('input, textarea, select', $container).on('input change', function () {
                        var $field = $(this).closest('div.wpforms-field');
                        if (this.value) {
                            $field.addClass('field--not-empty');
                        } else {
                            $field.removeClass('field--not-empty');
                        }
                    }).trigger('input');

                    $('input, textarea, select', $container).on('focusin', function () {
                        var $field = $(this).closest('div.wpforms-field');
                        $field.addClass('field--not-empty');
                    });

                    $('input, textarea, select', $container).on('focusout', function () {
                        $(this).trigger('input');
                    });
                } catch (e) {
                    console.error(e);
                }
            });
        });



    },
    resize: function () {

    },
    scroll: function () {

    }
};
