app.components.projects_slider = {
    onReady: function () {
        if ($('.c-projects-slider').length) {
            return true;
        }
    },
    addListener: async function () {
        try {
            const [Swiper, swiperModules] = await Promise.all([
                import(/* webpackChunkName: "swiper" */ 'swiper'),
                import(/* webpackChunkName: "swiperNavigation" */ 'swiper/modules'),
                import(/* webpackChunkName: "swiper" */ 'swiper/css')
            ]);
            const SwiperLib = Swiper.default;
            $('.c-projects-slider').each(function () {

                const params = {
                    modules: [ swiperModules.Navigation ],
                    speed: 1200,
                    spaceBetween: 0,
                    loop: false,
                    slidesPerView: 'auto',
                    watchOverflow: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                };

                if (typeof window.swiperwAccessibilityParameters !== 'undefined') {
                    swiper.params.a11y = window.swiperwAccessibilityParameters;
                }

                new SwiperLib($(".swiper-container", this)[0], params);
            });
        } catch (error) {
            console.error('Error loading modules:', error);
        }
    }
};
