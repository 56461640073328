app.components.counter = {
    global: false,
    onReady: function () {
        if ($('.c-counter').length) {
            return true;
        }
    },
    addListener: function () {
        const that = this;
        const counters = document.querySelectorAll('.c-counter__value');

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const counter = entry.target;
                    that.animate(counter);
                    observer.unobserve(counter); // Stop observing once the animation has started
                }
            });
        }, {threshold: 0.1});

        counters.forEach(counter => {
            observer.observe(counter);
        });
    },
    animate: function (element) {
        const targetNumber = parseInt(element.textContent, 10);
        element.textContent = '0';
        element.style.opacity = 1;
        let count = 0;
        const increment = targetNumber / (1500 / 16);

        const counterInterval = setInterval(() => {
            count += increment;
            if (count >= targetNumber) {
                count = targetNumber;
                clearInterval(counterInterval);
            }
            element.textContent = Math.floor(count);
        }, 16);
    }
};
