import $ from "expose-loader?exposes=$,jQuery!jquery";

/**
 * App object for managing components and initialization.
 */
window.app = {
    components: {}, // Object to store components.

    /**
     * Initializes the application.
     * Adds event listeners for resize, orientation change, and scroll.
     */
    init: function () {
        // Call the 'addListener' function on components.
        this.call('addListener');

        // Add event listeners for resize and orientation change.
        $(window).on('resize orientationchange', () => this.call('resize')).trigger('resize');

        // Add event listener for scroll.
        $(window).on('scroll', () => this.call('scroll')).trigger('scroll');
    },

    /**
     * Calls a specified event on all components.
     * @param {string|Object} event - The name of the event or an event object.
     */
    call: function (event) {
        var _return;
        if (typeof event === 'string') {
            event = {name: event, arg: this};
        }
        for (const element of Object.values(this.components)) {
            element.global = element.global || false;
            if (typeof element[event.name] === 'function' && (element.global || element.onReady())) {
                element[event.name](event.arg);
            }
        }
    },

};

$.fn.serializeArrayAll = function () {
    var rCRLF = /\r?\n/g;
    return this.map(function () {
        return this.elements ? jQuery.makeArray(this.elements) : this;
    }).map(function (i, elem) {
        var val = jQuery(this).val();
        if (val == null) {
            return val == null
            //next 2 lines of code look if it is a checkbox and set the value to blank
            //if it is unchecked
        } else if (this.type == "checkbox" && this.checked === false) {
            return {name: this.name, value: this.checked ? this.value : ''}
            //next lines are kept from default jQuery implementation and
            //default to all checkboxes = on
        } else if (this.type === 'radio') {
            if (this.checked) {
                return {name: this.name, value: this.checked ? this.value : ''};
            }
        } else {
            return jQuery.isArray(val) ?
                jQuery.map(val, function (val, i) {
                    return {name: elem.name, value: val.replace(rCRLF, "\r\n")};
                }) :
                {name: elem.name, value: val.replace(rCRLF, "\r\n")};
        }
    }).get();
};

// Scroll to element functionality for hash links.
window.scrollToElement = (hash) => {
    let elementOffset = 0;
    if (typeof hash === 'string' || hash instanceof String) {
        hash = hash.replace('#', '');
        elementOffset = $('.section--' + hash).offset();
        if (!elementOffset) {
            elementOffset = $('#' + hash).offset();
        }
    } else {
        elementOffset = hash.offset();
    }

    if (elementOffset) {
        elementOffset = elementOffset.top;
        $('html, body').stop().animate({
            'scrollTop': elementOffset - $('header').height() - 20
        }, 750, 'swing', function () {
            $(document).trigger("scroll");
        });
    }
}
