app.components.navigation = {
    onReady: function () {
        if ($('.c-navigation__toggle').length) {
            return true;
        }
    },
    addListener: function () {
        var navigationToggle = $('.c-navigation__toggle');
        navigationToggle.on("click", function () {
            $('html').toggleClass('mobile-nav-is-visible');
        });

        $('header .menu li.menu_item_has_children > a').on('click', function (e) {
            let that = this;
            if (!$(that).parent('li').hasClass('mouseenter')) {
                $('header .menu li.menu_item_has_children').removeClass('mouseenter')
                $(that).parent('li').addClass('mouseenter');
            } else {
                $(this).parent('li').removeClass('mouseenter');
            }
            return false;
        });

    }
};
