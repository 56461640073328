app.components.anniversary_galleries = {
    global: false,
    onReady: function () {

    },
    addListener: function () {

    },
    resize: function () {

    },
    scroll: function () {

    }
};