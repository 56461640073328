app.components.fancybox = {
    onReady: function () {
        if ($('[data-fancybox]').length || $('[data-fancybox-gallery]').length){
            return true;
        }
    },
    addListener: function () {

        Promise.all([
            import(/* webpackChunkName: "fancyapps" */ '@fancyapps/ui'),
            import(/* webpackChunkName: "fancyappscss" */ '@fancyapps/ui/dist/fancybox/fancybox.css')
        ]).then(([FancyboxModule, FancyboxCss]) => {

            const Fancybox = FancyboxModule.Fancybox;

            Fancybox.bind("[data-fancybox]", {
                fitToView: true,
                Images: {
                    zoom: false,
                },
                closeButton: true,
                on: {
                    done: (fancybox, slide) => {
                        try {
                            wpforms.ready();
                        } catch (e) {
                        }
                    },
                },
            });

            $('[data-fancybox-gallery]').on('click', function () {
                const data = $(this).data('gallery-json');
                new Fancybox(
                    data,
                    {
                        Toolbar: {
                            display: {
                                left: [],
                                middle: ["prev", "infobar", "next"],
                                right: ["close"],
                            },
                        },
                        Fullscreen: {
                            autoStart: false,
                        },
                        fitToView: true,
                        height: function () {
                            return screen.height * 0.8;
                        },
                        Images: {
                            zoom: false,
                            protected: true,
                        }
                    }
                );
            });

        }).catch(error => {
            console.error('Error loading modules:', error);
        });
    }
};
