import Cookies from 'js-cookie';

app.components.cookie_policy = {
    onReady: function () {
        if ($('.cookie_policy').length) {
            return true;
        }
    },
    addListener: function () {
        $('.cookie_policy').on('click', function () {
            $(this).addClass('close');
        });

        $('.cookie_policy button.read').on('click', function () {
            Cookies.set('CookieConsent', 1, {expires: 365, path: '/'});
        });

        if (!Cookies.get('CookieConsent')) {
            $('.cookie_policy').removeClass('close');
        }
    }
};
