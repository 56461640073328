app.components.form = {
    onReady: function () {
        if ($('.wpforms-container').length) {
            return true;
        }
    },
    addListener: function () {

        let component = this;

        let $container = $('.wpforms-container');

        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js')
        ]).then(() => {
            jcf.replaceAll($container);
        });

        let maxlength = 200;
        $('.wpf-char-limit textarea').attr('maxlength', maxlength);
        $('.wpf-char-limit textarea').each(function () {
            let id = $(this).attr('id');
            $(this).after('<span class="textarea_counter">Noch <span data-textarea="' + id + '">' + maxlength + '</span> Zeichen</span>');
        });
        $('.wpf-char-limit textarea').keyup(function () {
            var count = parseInt($(this).attr('maxlength')) - parseInt($(this).val().length);
            var id = $(this).attr('id');
            if (isNaN(count)) return false;
            $('span[data-textarea="' + id + '"]').text(count);
        }).trigger('keyup');

        $('input, textarea, select', $container).on('input change', function () {
            var $field = $(this).closest('div.wpforms-field');
            if (this.value) {
                $field.addClass('field--not-empty');
            } else {
                $field.removeClass('field--not-empty');
            }
        }).trigger('input');

        $('input, textarea, select', $container).on('focusin', function () {
            var $field = $(this).closest('div.wpforms-field');
            $field.addClass('field--not-empty');
        });

        $('input, textarea, select', $container).on('focusout', function () {
            $(this).trigger('input');
        });

        if ($(".membership-fee-output").length) {
            component.calculateMembershipFee();
        }

    },
    calculateMembershipFee: function () {

        const feeOutputLabel = $(".membership-fee-output label");
        const feeOutput = $(".membership-fee-output input");
        const feeInput = $(".membership-fee-input input");
        const feeInputLabel = $(".membership-fee-input label");
        const uploadLabel = $(".pdf-upload label");

        if (feeInput.length && feeOutput.length && feeOutputLabel.length && feeInputLabel.length) {
            feeInputLabel.text(feeInputLabel.text().replace("###year###", new Date().getFullYear() - 1));
            uploadLabel.text(uploadLabel.text().replace("###year###", new Date().getFullYear() - 1));
            feeOutput.prop("disabled", true);
            feeInput.on("input", function() {
                const payroll = parseFloat($(this).val());
                let fee = 700;
                const lowerBound = 106000;
                const upperBound = 2500000;
                if (payroll > lowerBound) {
                    fee = payroll * 0.0066;
                }
                if (payroll > upperBound) {
                    fee = 16500;
                }
                feeOutput.val(`CHF ${fee.toFixed(0)}`);
                feeOutputLabel.css('opacity', 1);
                if (!payroll) {
                    feeOutputLabel.css('opacity', 0.4);
                    feeOutput.val("");
                }
            });
        }
    }
};
