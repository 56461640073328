let THREE;

app.components.eye = {
    onReady: function () {
        if ($('#base-eye').length) {
            return true;
        }
    },
    addListener: async function () {

        [THREE] = await Promise.all([import(/* webpackChunkName: "three" */ 'three')]);

        if (THREE) {
            const eyeTracker = new EyeTracker("base-eye", "men--eye-grid", ".men");
        }

    }
};

class EyeTracker {
    constructor(baseObjectId, gridId, container, eyeDensity = 1) {
        this.baseObject = document.getElementById(baseObjectId);
        this.grid = document.getElementById(gridId);
        this.container = document.querySelector(container);
        this.eyeDensity = eyeDensity;
        this.eyes = [];
        this.eyeCenters = [];
        this.center = new THREE.Vector2();
        this.mousePos = new THREE.Vector2();
        this.PI = Math.PI;
        this.maxEyeTravelX = 250;
        this.maxEyeTravelY = 100;
        this.lookaroundDuration = 1250;
        this.init();
    }

    init() {
        // Initial setup and event binding
        window.addEventListener("resize", this.throttled(this.handleResize.bind(this)));
        this.handleResize();
        this.enableRealMouseTracking();
        $('html').addClass('mobile-nav-is-visible');
        // this.startLookaroundAnimation();
    }

    startLookaroundAnimation() {
        const centerOffset = 0.54;
        const corners = [
            new THREE.Vector2(0, innerHeight),
            new THREE.Vector2(innerWidth, innerHeight),
            new THREE.Vector2(innerWidth * centerOffset, innerHeight * centerOffset)
        ];

        // Simulate mouse movements to corners during the lookaround animation
        corners.forEach((corner, i) => {
            setTimeout(() => this.handleMouseMove({clientX: corner.x, clientY: corner.y}), i * this.lookaroundDuration);
        });

        // After the animation, enable real mouse tracking
        setTimeout(() => this.enableRealMouseTracking(), corners.length * this.lookaroundDuration);
    }

    enableRealMouseTracking() {
        this.container.dataset.menLoading = "false";
        window.addEventListener("mousemove", this.throttled(this.handleMouseMove.bind(this)));

        if (window.DeviceOrientationEvent) {
            window.addEventListener("deviceorientation", (event) => {
                const x = this.map(event.gamma, -90, 90, innerWidth, 0);
                this.handleMouseMove({clientX: x, clientY: innerHeight});
            });
        }
    }

    handleMouseMove(event) {
        this.mousePos.set(event.clientX, event.clientY);
        this.eyes.forEach((eye, i) => {
            const vecToMouse = new THREE.Vector2().subVectors(this.mousePos, this.eyeCenters[i]);
            const dist = this.mousePos.distanceTo(this.eyeCenters[i]);
            const clampedMouseX = this.clamp(vecToMouse.x, -this.maxEyeTravelX, this.maxEyeTravelX);
            const clampedMouseY = this.clamp(vecToMouse.y, -this.maxEyeTravelY, this.maxEyeTravelY);
            const pupilX = this.map(clampedMouseX, 0, this.maxEyeTravelX, 0, this.maxEyeTravelX);
            const pupilY = this.map(clampedMouseY, 0, this.maxEyeTravelY, 0, this.maxEyeTravelY);
            const scale = this.map(dist, 0, this.maxDist, 1, 1.15);

            eye.style.setProperty("--pupil-x", pupilX);
            eye.style.setProperty("--pupil-y", pupilY);
            eye.style.setProperty("--scale", scale);
        });
    }

    handleResize() {
        // Recalculate grid based on new window size
        const largeSide = Math.max(innerWidth, innerHeight);
        const size = Math.round(largeSide / this.eyeDensity);
        this.numEyesX = Math.ceil(innerWidth / size);
        this.numEyesY = Math.ceil(innerHeight / size);

        this.grid.innerHTML = ""; // Clear existing grid
        this.generateEyeGrid();

        this.center.set(innerWidth * 0.5, innerHeight * 0.5);
        this.maxDist = this.center.length() * 2;
    }

    generateEyeGrid() {
        this.eyes = [];
        this.eyeCenters = [];

        for (let i = 0; i < this.numEyesX * this.numEyesY; i++) {
            const newEye = this.baseObject.cloneNode(true);
            newEye.id = `eye-${i}`;
            newEye.classList.add("eye");
            this.grid.appendChild(newEye);
            this.eyes.push(newEye);

            const eyeRect = newEye.getBoundingClientRect();
            const eyeCenter = new THREE.Vector2(
                eyeRect.left + newEye.clientWidth * 0.5,
                eyeRect.top + newEye.clientHeight * 0.5
            );
            this.eyeCenters.push(eyeCenter);
        }
    }

    // Utility function to limit event calls using requestAnimationFrame
    throttled(fn) {
        let didRequest = false;
        return (param) => {
            if (!didRequest) {
                requestAnimationFrame(() => {
                    fn(param);
                    didRequest = false;
                });
                didRequest = true;
            }
        };
    }

    // Mapping value from one range to another
    map(value, min1, max1, min2, max2) {
        return ((value - min1) * (max2 - min2)) / (max1 - min1) + min2;
    }

    // Clamps a value between a minimum and a maximum
    clamp(value, min, max) {
        return Math.max(min, Math.min(max, value));
    }
}
