app.components.accordion = {
    onReady: function () {
        if ($('.c-accordion').length) {
            return true;
        }
    },
    addListener: function () {
        var that = this;
        $('.c-accordion').find('.c-accordion__header').on('click', function () {
            $(this).toggleClass('c-accordion__header--active');
            $('.c-accordion__header').not($(this)).removeClass('c-accordion__header--active');
            $(this).next().slideToggle('fast');
            $(".c-accordion__content").not($(this).next()).slideUp('fast');
        });
    }
};