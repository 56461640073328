app.components.project = {
    global: false,
    onReady: function () {
        if ($('.single-project').length) {
            return true;
        }
    },
    addListener: function () {

        Promise.all([
            import(/* webpackChunkName: "sticksy" */ 'sticksy/dist/sticksy.min')
        ]).then(() => {
            Sticksy.initializeAll('.row .project-slideshow', {
                topSpacing: 40
            });
        });

    }
};
